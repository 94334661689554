import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Collapse from '@material-ui/core/Collapse';
import FloatingCard from 'hcla-web-frontend-primitives/app/components/primitives/Card/FloatingCard';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  root: {
    height: 490,
  },
  container: {
    display: 'auto',
  },
  card: {
    cursor: 'pointer',
    margin: 'auto',
    padding: theme.spacing.unit * 2,
    maxWidth: '400px',
    height: '475px',
    flexDirection: 'column',
  },
  media: {
    margin: 'auto',
    width: '75%',
  },
  uppercase: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  bold: {
    fontWeight: 'bold',
  },
  description: {
    fontSize: '1rem',
    color: 'auto',
    display: 'auto',
    overflowY: 'auto',
    lineHeight: 'auto',
    maxHeight: 'auto',
  },
  cardActions: {
    display: 'auto',
  },
  cardContent: {
    display: 'auto',
  },
});

class CollapseAppCard extends React.Component {
  state = {
    checked: true,
  };

  handleChange = () => {
    this.setState(state => ({ checked: !state.checked }));
  };

  render() {
    const { checked } = this.state;
    const {
      classes, title, subheader, description, image,
    } = this.props;

    return (
      <div className={classes.root}>
        <FloatingCard className={classes.card} checked={checked} onMouseEnter={this.handleChange} onMouseLeave={this.handleChange} aria-label="Collapse">
          <CardHeader
            title={title}
            subheader={subheader}
            titleTypographyProps={{
              className: classes.bold,
            }}
            subheaderTypographyProps={{
              className: classes.uppercase,
              variant: 'caption',
              gutterBottom: false,
            }}
          />
          <Collapse in={checked}>
            <CardMedia
              component="img"
              className={classes.media}
              src={image}
            />
          </Collapse>
          <Collapse in={!checked}>
            <CardContent className={classes.cardContent}>
              <Typography component="p" className={classes.description}>
                {description}
              </Typography>
            </CardContent>
          </Collapse>
        </FloatingCard>
      </div>
    );
  }
}

CollapseAppCard.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default withStyles(styles)(CollapseAppCard);
