import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import HCLBranding from 'branding-asset-primitives/hcl/HCLLogo.png';
import CSBranding from 'branding-asset-primitives/cs/CSLogo.svg';
import PFBranding from 'branding-asset-primitives/pf/PFLogo.svg';
import PSIMBranding from 'branding-asset-primitives/psim/PSIMLogo.svg';
import BayesianEstimationBranding from 'branding-asset-primitives/bayesian-estimation/BayesianEstimationLogo.svg';
import FireLogo from '../../assets/FireLogo.svg'

import { GridItem } from './GridItems';
import CollapseAppCard from './CollapseAppCard';
import Apps from './Apps';

const styles = () => ({
});

const LevelTwoApps = (props) => {
  return (
    <Apps>
        {
            process.env.HCL_URL ?      
            <GridItem link={`${process.env.HCL_URL}`}>
            <CollapseAppCard
                title="Hybrid Causal Logic"
                subheader="Probabilistic Risk Assessment Tool"
                description="The Hybrid Causal Logic application is used to perform traditional probabilistic risk
                assessment (PRA) with time-to-failure models, importance measures, and uncertainty quantification."
                image={HCLBranding}
            />
        </GridItem> : null
        }
        {
            process.env.BAYESIAN_ESTIMATION_URL ?       
            <GridItem link={`${process.env.BAYESIAN_ESTIMATION_URL}`}>
            <CollapseAppCard
                title="Bayesian Estimation"
                subheader="Data Analysis Tool"
                description="The Bayesian Estimation Tool can perform Homogeneous and Non-Homogeneous Analyses. Within
                the risk and reliability community, they are commonly referred to as the second and first stages in
                Kaplan’s two-stage Bayesian updating procedure."
                image={BayesianEstimationBranding}
            />
        </GridItem> : null
        }
        {
            process.env.CIRCUIT_SIMULATION_URL ?       
            <GridItem link={`${process.env.CIRCUIT_SIMULATION_URL}`}>
            <CollapseAppCard
                title="Circuit Simulation"
                subheader="Reliability Analysis Tool"
                description="The Circuit Simulation application is used to obtain time-to-failure estimates of
                semiconductor devices using physics-of-failure modeling and circuit simulation."
                image={CSBranding}
            />
        </GridItem> : null
        }
        {
            process.env.PROCESS_FACTORS_URL ?       
            <GridItem link={`${process.env.PROCESS_FACTORS_URL}`}>
            <CollapseAppCard
                title="Process Factors"
                subheader="Reliability Analysis Tool"
                description="The Manufacturing Process Factors application is used to obtain quick adjustment factors
                for reliability estimation of microelectronic devices."
                image={PFBranding}
            />
        </GridItem> : null
        }
        {
            process.env.PSIM_URL ?       
            <GridItem link={`${process.env.PSIM_URL}`}>
            <CollapseAppCard
                title="PSIM"
                subheader="Pipeline System Integrity Management"
                description="PSIM is a software platform where the pipeline operators can observe the real-time and projected health state of the pipeline and the set of suggested actions to enhance the structural integrity of the pipeline system. The platform includes three main modules: Real-Time Health Monitoring, System-Level Reliability, and Optimal Mitigation Actions. From a safety perspective, this software can prevent pipeline failures or reduces their likelihood by supporting pipeline operators in optimal decision-making and planning activities. "
                image={PSIMBranding}
            />
        </GridItem> : null
        }
        {
            process.env.FIRE_RISK_URL ?         
            <GridItem link={`${process.env.FIRE_RISK_URL}`}>
            <CollapseAppCard
                title="Fire Risk Management"
                subheader="Fire Propagation Risk Modeling"
                description="Wildfire risk assessment platform is used to assess the wildfire risk level of a location due to different drivers such as power grids (distribution and transmission lines) ignitions and vegetation ignition. The platform can be used in three different modes of planning, event, and operational modes. "
                image={FireLogo}
            />
        </GridItem> : null
        }
    </Apps>
  );
};

LevelTwoApps.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(LevelTwoApps);
