import Grid from '@material-ui/core/Grid/Grid';
import React from 'react';

const GridItem = (props) => {
  const { children, link } = props;
  if (link && link !== 'undefined') {
    return (
        <Grid item xs={12} sm={6} md={4} lg={4} xl={2}>
          <a href={link} >
            {children}
          </a>
        </Grid>
    );
  } return null;
};

const GridItemSmall = (props) => {
  const { children, link } = props;
  if (link) {
    return (
        <Grid item xs={6} sm={3} md={2} lg={2} xl={1}>
          <a href={link} >
            {children}
          </a>
        </Grid>
    );
  } return null;
};

export { GridItem, GridItemSmall };
