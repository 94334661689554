import React from 'react';
import Footer from './components/Footer';
import LevelOneApps from './components/LevelOneApps';
import LevelThreeApps from './components/LevelThreeApps';
import LevelTwoApps from './components/LevelTwoApps';
/**
 * Represents the root container for this application.
 */
const Root = (props) => {
  const { classes } = props;
  return (
    <div>
      <LevelOneApps />
      <LevelTwoApps />
      <LevelThreeApps />
      <Footer />
    </div>
  );
};

export default Root;
