import React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@material-ui/core/CssBaseline';

import ThemeSettingsProvider from 'hcla-web-frontend-primitives/app/config/ThemeSettingsProvider';

import Root from './Root';

import ApiProvider from "hcla-web-frontend-primitives/app/components/primitives/Context/ApiContext";
import ApiManager from "hcla-web-frontend-primitives/app/Api/ApiManager";
import UCLATheme from "hcla-web-frontend-primitives/app/config/themes/UCLATheme";
import RobinTheme from "hcla-web-frontend-primitives/app/config/themes/RobinTheme";
import PreferencesProvider from "hcla-web-frontend-primitives/app/config/PreferencesProvider";
import {SharedIntlProvider} from "hcla-web-frontend-primitives/app/components/primitives/Intl/SharedIntlProvider";
import getMessageObj from 'hcla-web-frontend-primitives/app/components/primitives/Intl/messages';

import enPrimitiveMessages from 'hcla-web-frontend-primitives/app/translations/en.json';
import jaPrimitiveMessages from 'hcla-web-frontend-primitives/app/translations/ja.json';
import {SharedSnackbarProvider} from "hcla-web-frontend-primitives/app/components/primitives/Snackbar/SharedSnackbar.context";

const render = (Component) => {
  ReactDOM.render(
  <ApiProvider
      ApiManagerClass={ApiManager}
  >
      <PreferencesProvider>
          <ThemeSettingsProvider
            themeOptions={[RobinTheme]}
          >
              <CssBaseline />
              <SharedIntlProvider
                  messages={getMessageObj(
                      {...enPrimitiveMessages,}, {...jaPrimitiveMessages,},
                  )}
              >
                  <SharedSnackbarProvider>
                      <Component />
                  </SharedSnackbarProvider>
              </SharedIntlProvider>
          </ThemeSettingsProvider>
      </PreferencesProvider>
  </ApiProvider>,
  document.getElementById('root'),
  );
};

render(Root);
