import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import GitlabBranding from 'branding-asset-primitives/gitlab/Gitlab-GIRS.svg';
import SlackBranding from 'branding-asset-primitives/slack/SlackLogo.svg';

import { GridItemSmall } from './GridItems';
import SmallCard from './SmallCard';
import Apps from './Apps';

const styles = () => ({
});

const LevelThreeApps = (props) => {
  return (
    <Apps>
      <GridItemSmall link={`${process.env.GITLAB_URL}`}>
          <SmallCard
              title="Gitlab"
              subheader="Software Development Platform"
              description="From open source to private, you can host and review code, manage projects, and build software alongside your fellow developers."
              image={GitlabBranding}
          />
      </GridItemSmall>
      <GridItemSmall link={`${process.env.SLACK_URL}`}>
          <SmallCard
              title="Slack"
              subheader="Collaboration Tool"
              description=""
              image={SlackBranding}
          />
      </GridItemSmall>
    </Apps>
  );
};

LevelThreeApps.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(LevelThreeApps);
