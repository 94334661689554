import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';
import Divider from '@material-ui/core/Divider/Divider';
import Typography from '@material-ui/core/Typography/Typography';

const styles = () => ({
  root: {
    width: '90vw',
    margin: 'auto',
  },
  tagline: {
    padding: 8,
  },
});

const Footer = (props) => {
  const { classes } = props;
  return (
    <div className={classes.root}>
      <Divider />
      <Typography className={classes.tagline} variant="caption" align="center">
        JANUS - God of beginnings, gates, transitions, time, duality, doorways, passages, and ending
      </Typography>
    </div>
  );
};

Footer.defaultProps = {
  classes: PropTypes.instanceOf(Object).isRequired,
};
export default withStyles(styles)(Footer);
