import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import FloatingCard from 'hcla-web-frontend-primitives/app/components/primitives/Card/FloatingCard';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import Tooltip from '@material-ui/core/Tooltip';

const styles = theme => ({
  card: {
    cursor: 'pointer',
    margin: 'auto',
    padding: theme.spacing.unit / 2,
    maxWidth: '180px',
    display: 'block',
    flexDirection: 'column',
  },
  header: {
    padding: theme.spacing.unit * 2,
  },
  media: {
    margin: 'auto',
    width: '100px',
    height: '100px',
    paddingBottom: theme.spacing.unit * 2,
  },
  mediaContainer: {
    width: '100%',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
  },
  subtitle: {
    textTransform: 'uppercase',
    fontWeight: 'bold',
    fontSize: '0.6rem',
  },
  description: {
    fontSize: '0.875rem',
    color: '#414141',
    overflowY: 'auto',
    lineHeight: '1.8rem',
    maxHeight: '105px',
  },
});

const SmallCard = (props) => {
  const {
    classes, title, subheader, description, image,
  } = props;
  return (
    <Tooltip title={description}>
      <FloatingCard className={classes.card}>
        <CardHeader
          title={title}
          titleTypographyProps={{
            className: classes.title,
            variant: 'caption',
          }}
          subheaderTypographyProps={{
            className: classes.subtitle,
            variant: 'caption',
            gutterBottom: false,
          }}
        />
        <div className={classes.mediaContainer}>
          <CardMedia
            component="img"
            className={classes.media}
            src={image}
          />
        </div>
      </FloatingCard>
    </Tooltip>
  );
};

SmallCard.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  title: PropTypes.string.isRequired,
  subheader: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
};

export default withStyles(styles)(SmallCard);
