import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

import MainContent from 'hcla-web-frontend-primitives/app/components/primitives/Views/MainContent';
import Divider from '@material-ui/core/Divider/Divider';
import Grid from '@material-ui/core/Grid/Grid';

const styles = () => ({
  divider: {
    width: '90vw',
    margin: 'auto',
  },
  content2: {
    margin: 'auto',
  },
});

const Apps = (props) => {
  const { children, classes } = props;
  return (
    <div className={classes.root}>
      <MainContent padding={false}>
        <Grid container spacing={3} justify="center">
          {children}
        </Grid>
      </MainContent>
      <Divider className={classes.divider} />
    </div>
  );
};

Apps.propTypes = {
  classes: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.instanceOf(Object).isRequired,
};

export default withStyles(styles)(Apps);
